<template>
    <div class="content-page">
        <div class="container">
            <div class="content">
                <h3 class="content-title">{{ content.title }}</h3>
                <div class="" style="display: flex;align-items: center;gap:16px;margin-bottom: 16px;">
                    <div><span class="el-icon-time"></span> {{ content.createTime }}</div>
                    <el-tag type="success">{{
                  options.filter((item) => item.value == (content.type|| 0))[0]?.label
                }}</el-tag>
                </div>
                <div class="alert">
                    为了保证大家可以永久下载资源，请先<b>注册账户</b>后再进行下载！
                </div>
                <div v-if="content.content" class="content-text" v-html="content.content"></div> 
                <div class="download-section-2">
                    <div class="platform-2">请使用百度网盘进行下载</div>
                    <div v-if="content.bought || content.isVip"> <!-- 如果已购买则显示下载链接 -->
                        <a :href="content.link" class="download-link" target="_blank">{{ content.link }}</a>
                    </div>
                    <div v-else-if="!downloadLink"> <!-- 未购买且没有下载链接时显示价格与购买按钮 -->
                        下载价格 <span class="points">{{ content.coinNumber }}</span> 积分
                        <button class="buy-now-2" @click="handleBuy">立即购买</button>
                    </div>
                </div>
                <div style="text-align: left;">
                    © 版权声明 <br />
                    <div class="posts-copyright">      
                <span class="btn-info btn-xs">1</span> 本网站名称：八戒指标网<br>

            <span class="btn-info btn-xs">2</span>  本网站的文章部分内容可能来源于网络，仅供大家学习与参考，如有侵权，请联系客服进行删除处理 <br>


                <span class="btn-info btn-xs">3</span> 本站一切资源不代表本站立场，并不代表本站赞同其观点和对其真实性负责<br>

            <span class="btn-info btn-xs">4</span> 本站一律禁止以任何方式发布或转载任何违法的相关信息，访客发现请向客服举报<br>
            </div>
                </div>
            </div>
            <div class="box">
                <div class="download-section">
                    <div class="platform">请使用百度网盘进行下载</div>
                    <div v-if=" (content.bought || content.isVip)"> <!-- 如果已购买则显示下载链接 -->
                        <a :href="content.link" class="download-link" target="_blank">{{ content.link }}</a>
                    </div>
                    <div v-else-if="!downloadLink"> <!-- 未购买且没有下载链接时显示价格与购买按钮 -->
                        下载价格 <span class="points">{{ content.coinNumber }}</span> 积分
                        <button class="buy-now" @click="handleBuy">立即购买</button>
                    </div>
                </div>
                <img src="/static/logo.jpg" alt="Logo" class="logo">
                <div class="slider-wrap">
                    <div class="slider-title">温馨提示</div>
                    <div class="slider-content">本网站所有代码仅作为您的学习研究案例，若您想用于实际操作中，还需要进一步优化研究根据实际情况多因素考虑后再操作！</div>
                </div>
                <div class="slider-wrap">
                    <div class="slider-title">热门资源</div>
                    <div>
                        <div @click="toDetail(item)" v-for="item in hotList" class="hot-item">
                            <img class="hot-img" :src="item.url || 'https://tse3-mm.cn.bing.net/th/id/OIP-C.mIFhOinyZIi0CThCeqWF5AHaEJ?w=268&h=180&c=7&r=0&o=5&dpr=2&pid=1.7'" alt="">
                            <div>{{ item.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <nav class="article-nav" style="height:99px">
            <div class="main-bg box-body radius8 main-shadow">
                <a :href="`/content/${preList[0]?.id}`">
                    <p class="muted-2-color"><i class="fa fa-angle-left em12"></i><i class="fa fa-angle-left em12 mr6"></i>上一篇</p>
                    <div class="text-ellipsis-2">
                        {{ preList[0]?.title }}</div>
                </a>
            </div>
            <div class="main-bg box-body radius8 main-shadow" v-if="preList.length > 1">
                <a :href="`/content/${preList[1]?.id}`">
                    <p class="muted-2-color">下一篇<i class="fa fa-angle-right em12 ml6"></i><i class="fa fa-angle-right em12"></i></p>
                    <div class="text-ellipsis-2">{{ preList[1]?.title }}</div>
                </a>
            </div>
        </nav>
    </div>
</template>

<script>
import { ContentDataDisplay, buyItem , CourseDataDisplay, ContentDataDisplay2} from '@/api/display.js';
import store from '@/store'
export default {
    name: 'Content',
    data() {
        return {
            content: {
                title: '',
                content: '', // 详细内容
                coinNumber: 0,
                bought: false,
                link: '',
                url: '',
                images: []
            },
            hotList:[],
            currentPage: 1,      // 当前页码
            pageSize: 15,        // 每页显示的课程数量
            options: [
                { label: '免费课程', value: 0 },
                { label: '付费课程', value: 1 },
                { label: '小作文', value: 2 },
                { label: 'VIP课程', value: 3 },
                { label: '名师课程', value: 4 },
            ],
            preList: [],
            coinNumber: 200, // 假设的用户余额
            downloadLink: "", // 用于存储下载链接
        };
    },
    async created() {
    const id = this.$route.params.id; // 从路由参数中获取课程 ID
    console.log("id",id);
    try {
        const response = await ContentDataDisplay(id); // 调用 API 获取内容数据
        const res2 = await ContentDataDisplay2({id}); 
        this.preList = res2.data// 调用 API 获取内容数据
        this.content = response.data; // 假设返回的数据结构符合预期
        console.log(this.content);
        let res = await CourseDataDisplay({serchType: 2,type: 0, page: this.currentPage, 
            pageSize: this.pageSize,})
        console.log('res222', this.preList)
        this.hotList = res.data.records
        console.log('records',this.hotList)
        // 检查是否已购买并设置下载链接
        this.downloadLink = this.content.bought ? this.content.link : ""; // 如果已购买，则获取下载链接

        // 从 localStorage 获取用户余额
        const coinNumber = localStorage.getItem('coinNumber') ;
        console.log(coinNumber);
        this.coinNumber = coinNumber !== null ? parseInt(coinNumber, 10) : 0; // 如果没有，默认设置为 0
    } catch (error) {
        console.error('获取内容数据时出错:', error);
    }
},

    methods: {
        toDetail(item) {
            this.$router.replace({
                params: {
                    id: item.id
                }
            })
        },
        async handleBuy() {
        const courseId = this.$route.params.id; // 获取课程 ID
        const coursePrice = this.content.coinNumber; // 获取课程金币价格

        // 检查用户余额是否足够
        if (this.coinNumber < coursePrice) {
            this.$message.error("用户余额不足，请充值！");
            return;
        }

        // 检查是否已购买
        if (this.content.bought) {
            this.$message.info("您已购买过该课程，直接下载即可！");
            this.downloadLink = this.content.link; // 设置下载链接
            return;
        }

        try {
            const response = await buyItem(courseId, coursePrice, this.coinNumber);
            console.log(response.bought);
            // if (response.bought) {
                this.$message.success("购买成功!");
                this.downloadLink = response.link; // 将下载链接赋值给 downloadLink
                
                // 更新用户余额并存储到 localStorage
                const newBalance = this.coinNumber - coursePrice; // 更新余额
                this.coinNumber = newBalance; // 更新组件的数据
                localStorage.setItem('coinNumber', newBalance); // 更新 localStorage
                store.commit('setCoin', newBalance);
                // 重新获取内容数据以更新页面
                await this.refreshContentData(courseId);
            // } else {
            //     this.$message.error("购买失败，请重试！");
            // }
        } catch (error) {
            this.$message.error("购买失败，请重试！");
            this.$message.error("发生错误: " + error.message);
        }
    },

    // 新增方法以刷新内容数据
    async refreshContentData(courseId) {
        try {
            const response = await ContentDataDisplay(courseId); // 调用 API 获取最新内容数据
            this.content = response.data; // 更新组件的 content 数据
            this.downloadLink = this.content.bought ? this.content.link : ""; // 如果已购买，则获取下载链接
        } catch (error) {
            console.error('获取内容数据时出错:', error);
        }
    }
    }
}
</script>

<style scoped>
.article-nav {
    display: flex;
    justify-content: space-between;
    max-width: calc(100% - 290px);
    margin-top: 16px;
    background-color: #fff;
    border-radius: 16px;
    box-sizing: border-box;
    background-color: #f9f6f6;
    gap: 20px;
}
.main-bg {
    background: #fff;
    width: 100%;
    margin: 0;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
}
.main-shadow {
    box-shadow: 0 0 10px rgba(116, 116, 116, 0.08);
}
.muted-2-color {
    color: #999;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
.hot-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    cursor: pointer;
}
.hot-img {
    width: 96px;
    min-width: 96px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 4px;
}
.content-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f6f6;
}
.content-title {
    font-size: 24px;
    text-align: left;
    margin-bottom: 16px;
}
.container {
    display: flex;
    width: 100%; 
}

.content-text {
    font-size: 20px; /* 设置字体大小，可以根据需要调整 */
    color: #333; /* 字体颜色 */
    line-height: 1.6; /* 行高 */
    text-align: left;
}
.content-text * {
    word-break: break-all;
}
::v-deep .content-text  img {
    max-width: 800px; /* 最大宽度为容器的100%，保证图片响应式 */
    height: auto; /* 高度自动，保持宽高比 */
    display: block; /* 使图片在新行中显示 */
    margin: 10px 0; /* 添加上下间距 */
    
}

.box {
    width: 250px; /* 设置盒子的宽度 */
    min-width: 250px;
    max-width: 250px;
    overflow: hidden;
    background-color: #f9f6f6; /* 设置盒子的背景颜色 */
    display: flex; /* 使盒子内部也使用 Flexbox */
    align-items:start; /* 垂直居中 logo */
    flex-direction: column;
    padding: 10px;
    border-radius: 16px;
}

.logo {
    width: 100%;
    height: auto;
}

.content {
    margin-right: 20px; /* 内容与盒子之间的间距 */
    flex-grow: 1; /* 内容区域占据剩余的空间 */
    overflow: hidden;
    background-color: #fff;
    padding: 16px;
    border-radius: 16px;
}

/* .title {
    font-size: 28px;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
} */

.alert {
    background: linear-gradient(to right, #4a90e2, #ff4081);
    color: white;
    padding: 10px;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 20px; /* 增大提示字体 */
}

p {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
    line-height: 1.6;
}

.download-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    width: 230px;
}

.download-section .points {
    font-size: 20px; /* 增大下载价格字体 */
}

.download-link {
    font-size: 20px; /* 增大链接字体 */
    color: #ff5722; /* 链接颜色可以自定义 */
    text-decoration: underline; /* 添加下划线效果 */
    word-break: break-all;
}

.platform {
    background: #cccccc;
    padding: 10px;
    border-radius: 3px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 16px;
}

.price {
    flex-grow: 1;
    color: #333;
    font-size: 16px;
}

.points {
    color: #ff5722;
    font-weight: bold;
}

.buy-now {
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
}
.slider-wrap {
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 0 10px rgba(116, 116, 116, 0.08);
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 16px;
    text-align: left;
}
.slider-title {
    font-size: 15px;
    margin-bottom: 8px;
}
.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}
.download-section-2 {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.download-section-2 .points {
    font-size: 20px; /* 增大下载价格字体 */
}
.platform-2 {
    margin-right: 20px;
    background: #cccccc;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 16px;
}
.buy-now-2 {
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 420px;
}
</style>